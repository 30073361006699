window.$ = window.jQuery = require('jquery')

import Grid from './Grid.js'

const instaFeeds = $('#instagrams')

$(function() {
  // Grid behaviour
  let grid = new Grid()
  grid.init()

  if (typeof images !== 'undefined' && instaFeeds) {
    let image = images[Math.floor(Math.random() * images.length)]

    $.ajax({
      url: `https://api.instagram.com/oembed/?url=https://www.instagram.com/p/${image}/`,
      type: 'get'
    }).done(response => {
      instaFeeds.html(response.html)
    })
  }
})

/**
 * Scroll actions
 */
const headerElem = document.querySelector('.header')
const pxHideBranding = 1
const pxChangeBg = 200
let lastScrollPos = 0

document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('scroll', () => {
    let os = window.pageYOffset || document.documentElement.scrollTop

    if (os < pxHideBranding) {
      headerElem.classList.remove('is-branded')
      headerElem.classList.remove('is-sticky')
      headerElem.classList.remove('is-inverted')
    } else {
      if (os > lastScrollPos) {
        headerElem.classList.remove('is-branded')

        if (os > 0) {
          headerElem.classList.add('is-sticky')
        }

        if (os > pxChangeBg) {
          headerElem.classList.add('is-inverted')
        }
      } else {
        if (os > pxHideBranding) {
          headerElem.classList.add('is-branded')
        }
      }

      lastScrollPos = os <= 0 ? 0 : os
    }
  })
})

/**
 * Navigation toggler
 */
const navElem = document.querySelector('.navigation')
const navToggler = document.querySelector('.header__toggler')
const navClose = document.querySelector('.navigation__close')

if (navToggler && navElem) {
  document.addEventListener('DOMContentLoaded', () => {
    navToggler.addEventListener('click', () => {
      if (navElem.classList.contains('is-open')) {
        navElem.classList.remove('is-open')
      } else {
        navElem.classList.add('is-open')
      }
    })

    navClose.addEventListener('click', () => {
      navElem.classList.remove('is-open')
    })
  })
}

/**
 * Cookie consent
 */
const consented = window.localStorage.getItem('vwCookieAccepted')
const cookiebarElem = document.querySelector('.cookiebar')
const cookiebarAccept = document.querySelector('.cookiebar__button[data-cookiebar-accept]')

if (!consented && cookiebarElem) {
  document.addEventListener('DOMContentLoaded', () => {
    cookiebarElem.style.display = 'block'

    cookiebarAccept.addEventListener('click', e => {
      e.preventDefault()
      window.localStorage.setItem('vwCookieAccepted', true)
      cookiebarElem.style.display = 'none'
    })
  })
}
