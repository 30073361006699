import ScrollReveal from 'scrollreveal'

class Grid {
    constructor() {
        this.gridElement = '.grid__item'
    }

    init() {
        if (this.gridElement) {
            window.sr = ScrollReveal()

            sr.reveal('.grid__item', {
                delay: 200,
                duration: 500
            })
        }
    }
}

export default Grid
